html {
  background-color: #ebebeb !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.memeCardParent {
  position: relative;
}

.memeCard {
  object-fit: cover;
  width: 250px;
  height: 250px;
  border-radius: 5px;
  transition: 0.2s;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 90%, rgba(2,0,36,1) 100%);
}

.memeCard:hover {
  transition: 0.2s;
  -webkit-filter: brightness(70%);
  cursor: pointer;
}

.memeCardName {
  color: white;
  background-color: #3f3f3f;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  font-size: large;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  opacity: .8;
}

.memeVoteBox {
  position: absolute;
  top: 100%;
  transform: translate(0%, -100%);
  display: flex;
}

.memeUpvote{
  color: white;
  background-color: #28a745 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  height: 30px;
  font-size: medium;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.memeDownvote{
  color: white;
  background-color: #dc3545 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  height: 30px;
  transform: scaleY(-1);
  font-size: medium;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.memeScore{
  padding: 2px 4px 2px 4px;
  color: white;
  background-color: #ddc03d;
  opacity: .8;
  border-radius: 5px;
  font-weight: bold;
}

.grow {
  flex-grow: 1;
}

.grow-3 {
  flex-grow: 3;
}

.memeButton {
  background-color: #ddc03d !important;
  border-color: #ddc03d ! important;
}

.specialModalHeader {
  padding: 0px !important;
}

.specialModalHeader img {
  border-radius: 5px;
}

.keyword {
  transition: 0.5s;
  border: 1px solid #ddc03d;
  border-radius: 20px;
}

.keyword:hover {
  transition: 0.5s;
  background-color: #ddc03d;
  color: white;
  cursor: pointer;
}

.topSearch {
  border-radius: 5px !important;
  background-color: rgb(233, 233, 233) !important;
  color: rgb(209, 209, 209);
  border: 0px solid black;
}